<template>
  <base-section id="reglamentos" class="mt-n6">
    <v-container>
      <v-row>
        <v-col>
          <h2 class="font-weight-bold mb-0 text-left red--text text-center mb-4">
            <!--Consejo Mexicano de Patología Clínica y Medicina de Laboratorio, A. C.-->
            ESTATUTO Y REGLAMENTOS
          </h2>
          <!--<h3 style="color: #42A5F5;" class="text-bold mb-2"
          >Para consultar los documentos haga click en el título correspondiente</h3>-->
          <v-divider inset class="mb-3"></v-divider>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
        >
          <v-row>
            <v-col
              v-for="(reglamento, i) in reglamentos"
              :key="'reglamento_item_'+i"
              cols="12"
              md="6"
            >

              <div class="d-flex align-center">
                  <v-img  :src="reglamento.src" style="max-height: 38px; max-width: 38px; cursor: pointer"  @click.prevent="getfile(reglamento)" />
                <h4
                  style="cursor: pointer"
                  class="ml-5"
                  v-text="reglamento.title"
                  @click.prevent="getfile(reglamento)"
                >
                </h4>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionReglamentos',
    data: () => ({
      reglamentos: [
       
      {
        title: 'Estatuto',
        src: require('@/assets/compac/iconos/file-pdf.svg'),
        ruta_pdf:process.env.VUE_APP_BASE_URL+'/reglamentos/estatuto compacml 2023.pdf',
      },
        
        {
          title: 'Reglamento Comité de Certificación',
          src: require('@/assets/compac/iconos/file-pdf.svg'),
          ruta_pdf:process.env.VUE_APP_BASE_URL+'/reglamentos/reglamento de certificacion 2023.pdf',
        },
        
        {
          title: 'Reglamento del Comité de Recertificación',
          src: require('@/assets/compac/iconos/file-pdf.svg'),
          ruta_pdf:process.env.VUE_APP_BASE_URL+'/reglamentos/reglamento del comite de recertificacion 2023.pdf',
        },
        
        {

          title: 'Reglamento Comité de Estatutos y Reglamentos',
          src: require('@/assets/compac/iconos/file-pdf.svg'),
          ruta_pdf:process.env.VUE_APP_BASE_URL+'/reglamentos/Comite_de_Estatutos_y_Reglamentos_2019.pdf',
        },
        {

          title: 'Reglamento del Comité de Ética',
          src: require('@/assets/compac/iconos/file-pdf.svg'),
          ruta_pdf:process.env.VUE_APP_BASE_URL+'/reglamentos/reglamento_comite_de_etica_2023.pdf',
        },
        {

          title: 'Reglamento Código de Ética',
          src: require('@/assets/compac/iconos/file-pdf.svg'),
          ruta_pdf:process.env.VUE_APP_BASE_URL+'/reglamentos/Reglamento_Código_de_Ética_2019.pdf',
        },
        {
          title: 'Reglamento del Comité de Residencias',
          src: require('@/assets/compac/iconos/file-pdf.svg'),
          ruta_pdf:process.env.VUE_APP_BASE_URL+'/reglamentos/Reglamento_Comité_de_Residencias_2019.pdf',
        },
        {
          title: 'Reglamento del Comité de Educación Continua',
          src: require('@/assets/compac/iconos/file-pdf.svg'),
          ruta_pdf:process.env.VUE_APP_BASE_URL+'/reglamentos/Reglamento_de_Educación_Continúa_2019.pdf',
        },
        {
          title: 'Reglamento Comité de Custodia de la Información Histórica',
          src: require('@/assets/compac/iconos/file-pdf.svg'),
          ruta_pdf:process.env.VUE_APP_BASE_URL+'/reglamentos/Reglamento_Comité_de_Custodia_de_la_información_Histórica_2019.pdf',
        },
        {
          title: 'Manual de Procedimiento de la Transparencia y Rendición de cuentas',
          src: require('@/assets/compac/iconos/file-pdf.svg'),
          ruta_pdf:process.env.VUE_APP_BASE_URL+'/reglamentos/Manual_de_Procedimiento_de_la_transparencia_y_rendición_de_cuentas_2017.pdf',
        },
        {
          title: 'Manual Lex Artis Ad Hoc',
          src: require('@/assets/compac/iconos/file-pdf.svg'),
          ruta_pdf:process.env.VUE_APP_BASE_URL+'/reglamentos/Manual_Lex_Artis_Ad_Hoc.pdf',
        },
      ],
    }),
    methods:{
      getfile(reglamento){
        window.open(reglamento.ruta_pdf, "_blank");

      }
    }
  }
</script>
